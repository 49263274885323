import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "beaconx-all.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                <div>
                    <div className="main-text"> Beacon <span>X.</span></div>
                    <div className="sub-text">  Let your customers be in control.</div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className = 'child'>
                    <h3> No more missed pickups. </h3>
                    <p>
                    With our accurate predictions of the arrival time of the collection vehicle, your customers will never 
                    miss another pickup. All information about contracts and planned pickups is easily accessible in the application. 
                    </p>
                </div>
                <div className = 'child right'>
                    <h3> Towards high quality waste.  </h3>
                    <p>
                    Give your customers all information about their waste with our dashboards. What happens to the waste 
                    after it is picked up? How much waste did the customers produce this month? Together we work towards 
                    less waste of better quality. 
                    </p>
                </div>
                <div className = 'child'>
                    <h3> Dynamic collection. </h3>
                    <p>
                    Through our application clients can easily request pickups or other services. Our algorithms make sure 
                    each request is being fulfilled in the most optimal way. 
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Hero