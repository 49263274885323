import React from "react"
import { FaPhoneVolume, FaRecycle, FaAddressCard} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
            <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Features. </div>
                <div className="sub-text">  Select the components that fit your processes.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Manage.</div>
                    <div> <FaAddressCard size={50} color={"#7FACD6"}/> </div>
                    <div>Information on the arrival time of the vehicle</div>
                    <div>Access to pickup and contract information. </div>
                    <div>Allow your customer to make changes to the current contract. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Insight into waste.</div>
                    <div> <FaRecycle size={50} color={"#7FACD6"}/> </div>
                    <div>A waste analysis tool to analyze created and collected waste. </div>
                    <div>Statistics on sustainability and waste quality. </div>
                    <div>Allow your client to follow their waste form pickup to processing. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Effortless contact.</div>
                    <div> <FaPhoneVolume size={50} color={"#7FACD6"}/> </div>
                    <div>Allow your clients to easily request new pickups. </div>
                    <div>Directly communicate with the client.</div>
                    <div>Automatically resolve client requests. </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features